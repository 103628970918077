<template>

    <div class="popup active">
        <div class="prjdt-popup">
            <div class="close">
                <img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
            </div>
            <div class="title-txt">담당자 교체리스트<br/>
                <p><span class="prj_ct">교체하실 담당자를 선택 후 '담당자 교체' 버튼을 클릭하세요!</span></p>
            </div>
            <div class="filter">
                <div class="search_box">
					<SelectComp type="select" class="prj_name" list="1:담당자명,2:부서명,3:직급" v-model="input.searchGubun"/>						
					<InputComp type="text" classNm="search_box int" :placeholder="input.searchGubun == '1' ? '담당자명' : input.searchGubun == '2' ? '부서명' : '직급'" 
								v-model="input.searchKeyword" @keyup.enter="getProjectMgrList('1')"  />
					<nbsp/><div class="search_btn" @click="getProjectMgrList('1')"><img class="search" src="/images/search_btn.png" alt="검색버튼"/></div>
                </div>
                <div class="filter_box prj_list change">
                    <ul>
                        <li><img :class="input.orderGubun == '1' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('1')">가입일 순</p><span>ㅣ</span></li>
						<li><img :class="input.orderGubun == '2' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('2')">담당자명 순</p></li>
                    </ul>
                    <div class="mn_change" @click="clickChangeCorpMgrMberNm()">
                        <img src="/images/change_btn.png" alt="담당자교체"/><p>담당자교체</p>
                    </div>
                </div>
            </div>

            <div class="Board type3">
                <table class="Board_type3 demand">
                    <colgroup>
                        <col width="5%">
                        <col width="5%">
                        <col width="12%">
                        <col width="22%">
                        <col width="22%">
                        <col width="22%">
                        <col width="12%">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>선택</th>
                            <th>NO</th>
                            <th>담당자</th>
                            <th>아이디</th>
                            <th>이메일</th>
                            <th>부서</th>
                            <th>직급</th>
                        </tr>
                    </thead>
                    <tbody>
                        
						<tr v-for="(projectMgr, index) in projectMgrList" :key="projectMgr.mgrMberSeq">
                            <td class="radio">
                                <input type="radio" v-model="input.mgrMberSeq" :id="index" :value="projectMgr.mgrMberSeq">
                                <label :for="index">
                                    <span></span>
                                </label>
                            </td>
                            <td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</td>
                            <td class="score">{{projectMgr.mgrMberNm}}</td>
                            <td class="score"><div v-if="projectMgr.newYn == 'Y'" class="new">new</div>{{projectMgr.loginId}}</td>
                            <td class="score">{{projectMgr.email}}</td>
                            <td class="score">{{projectMgr.deptNm}}</td>
                            <td class="score">{{projectMgr.positionNm}}</td>
                        </tr>

                        <!-- 담당자가 없을 경우 -->
                        <tr v-if="pageInfo.totalRecordCount == 0">
                            <td colspan="7" class="none">교체하실 담당자가 없습니다!</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
			<!--페이징 컴포넌트 -->
			<PagingComp :pageInfo="pageInfo" @page-click="goPage"/>
			<!--// 페이징 컴포넌트 -->
            
        </div>
    </div>


</template>

<script>

import PagingComp from '@/components/PagingComp.vue';

export default {

	components : {
		PagingComp
	},

	props: ['param'],

	data() {
		return {
			input: {
				pageIndex : '1',
				orderGubun : '1',	// 1:가입일 순, 2:담당자 순
				searchGubun : '1',	// 1:담당자명, 2:부서명, 3:직급
				searchKeyword : '',
			}, // 검색조건		
			projectMgrList : [],
			pageInfo : {},
			
		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	mounted() {
		// console.log('mounted');
		// console.log(this.param);
		this.getProjectMgrList();
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		getProjectMgrList(div) {
			if(div) this.input.pageIndex = 1;
			
			this.$.httpPost('/api/prj/dco/gmg/getProjectMgrList', this.input)
				.then(res => {
					// console.log(res.data);
					this.projectMgrList = res.data.list;
					this.pageInfo = res.data.pageInfo;
					
					window.scroll(0, 0);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},

		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getProjectMgrList();
		},

		orderSearch(orderGubun) {
			this.input.orderGubun = orderGubun;
			this.input.pageIndex = 1;
			this.getProjectMgrList();
		},

		clickChangeCorpMgrMberNm() {

			var input = {
				projectSeq	: this.param.projectSeq,
				mgrMberSeq	: this.input.mgrMberSeq,
				parentProjectYn : this.param.parentProjectYn,
			}
			if(input.mgrMberSeq == undefined) {
				alert("교체하실 담당자를 선택해주세요.");
			} else {
				this.$.httpPost('/api/prj/dco/gmg/updateProjecMgr', input)
				.then(() => {
					alert('교체 되었습니다.');
					this.$emit('close');
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});	
			}
			
		},



	},
};
</script>
